import type { NextPage } from 'next';
import {
  Button,
  Heading,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { LoginLayout } from 'src/components/Layout/Login';
import Link from 'next/link';

const Error404Page: NextPage = () => {
  const secondaryTextColor = useColorModeValue('gray.900', 'gray.50');

  return (
    <LoginLayout>
      <Stack>
        <Stack marginBottom="8">
          <Text
            fontSize="xs"
            color={secondaryTextColor}
          >
            ERRO 404
          </Text>
          <Heading fontSize="xl">
            Não encontramos a página que você está procurando!
          </Heading>
          <Text
            fontSize="lg"
            color={secondaryTextColor}
          >
            Mas tudo bem, vamos começar pelo início.
          </Text>
        </Stack>
        <Button
          variant="outline"
          as={Link}
          href="/"
          size="sm"
          width="fit-content"
        >
          Ir para o início
        </Button>
      </Stack>
    </LoginLayout>
  );
};

export default Error404Page;
